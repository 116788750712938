import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as zod from 'zod'

import { getMinorUnitKeyValue, getMinorUnitNumericValue, minotUnitEmptyValue } from 'pages/currencies/utils'
import { Currency, CurrencyForm, CurrencyFormDTO } from 'types/masterData/currencies'
import { numericRegExp, uppercaseRegExp } from 'utils/regexp'

export const defaultValues: CurrencyForm = {
  name: '',
  aliases: [],
  isoAlpha3: '',
  numCode: '',
  minorUnit: minotUnitEmptyValue,
  withdrawalDate: null,
  meta: null,
}

export const convertToForm = (values: Currency): CurrencyForm =>
  values && {
    ...values,
    aliases: (values.aliases || []).map(item => ({ id: item, label: item })),
    meta: values?.meta ? JSON.stringify(values.meta) : null,
    minorUnit: getMinorUnitKeyValue(values.minorUnit),
  }

export const convertFormToFormDto = (values: CurrencyForm): CurrencyFormDTO => ({
  ...values,
  aliases: values.aliases.map(item => item.id.toString()),
  minorUnit: getMinorUnitNumericValue(values.minorUnit),
})

export const nameFieldCharacterMaxCount = 256
export const nameFieldCharacterMinCount = 1
export const aliasesFieldCharacterMinCount = 2
export const aliasesFieldCharacterMaxCount = 100
export const isoAlpha3FieldCharacterCount = 3
export const numCodeFieldCharacterCount = 3
export const withdrawalDateFieldCharacterCount = 30

export const useCurrencyValidationScheme = () => {
  const { t } = useTranslation()

  return useMemo(
    () =>
      zod.object({
        name: zod
          .string()
          .trim()
          .min(
            nameFieldCharacterMinCount,
            t('master_data.validation.name.min', {
              entity: t('common.master_data.entities.currency'),
              characterCount: nameFieldCharacterMinCount,
            }),
          )
          .max(
            nameFieldCharacterMaxCount,
            t('master_data.validation.name.max', {
              entity: t('common.master_data.entities.currency'),
              characterCount: nameFieldCharacterMaxCount,
            }),
          ),
        aliases: zod.array(
          zod.object({
            id: zod
              .string()
              .min(aliasesFieldCharacterMinCount, {
                message: t('master_data.validation.aliases.min', {
                  characterCount: aliasesFieldCharacterMinCount,
                }),
              })
              .max(aliasesFieldCharacterMaxCount, {
                message: t('master_data.validation.aliases.max', {
                  characterCount: aliasesFieldCharacterMaxCount,
                }),
              }),
            label: zod.string(),
          }),
        ),
        isoAlpha3: zod
          .string()
          .trim()
          .regex(uppercaseRegExp, t('master_data.validation.iso_alpha_3_currency.uppercase'))
          .min(
            isoAlpha3FieldCharacterCount,
            t('master_data.validation.iso_alpha_3_currency.length', {
              entity: t('common.master_data.entities.currency'),
              characterCount: isoAlpha3FieldCharacterCount,
            }),
          )
          .max(
            isoAlpha3FieldCharacterCount,
            t('master_data.validation.iso_alpha_3.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: isoAlpha3FieldCharacterCount,
            }),
          ),
        numCode: zod
          .string()
          .trim()
          .regex(numericRegExp, t('master_data.validation.num_code.numeric'))
          .min(
            numCodeFieldCharacterCount,
            t('master_data.validation.num_code.length', {
              entity: t('common.master_data.entities.currency'),
              characterCount: numCodeFieldCharacterCount,
            }),
          )
          .max(
            numCodeFieldCharacterCount,
            t('master_data.validation.num_code.length', {
              entity: t('common.master_data.entities.market'),
              characterCount: numCodeFieldCharacterCount,
            }),
          ),
        minorUnit: zod.string(),
        withdrawalDate: zod
          .string()
          .max(
            withdrawalDateFieldCharacterCount,
            t('master_data.validation.withdrawal_date.length', {
              entity: t('master_data.fields.withdrawal_date.label'),
              characterCount: withdrawalDateFieldCharacterCount,
            }),
          )
          .nullable(),
      }),
    [t],
  )
}

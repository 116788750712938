import { convertFormValuesFieldToFormDTO } from 'components/merge/utils'
import { defaultFormValues } from 'pages/clients/merge/form/defaultValues'
import { ConvertCurrencyFormProps, MergeCurrenciesForm } from 'pages/currencies/merge/form/types'
import { convertToTwoEntityValues } from 'pages/currencies/merge/twoEntities/utils'
import { Currency, CurrencyFormDTO } from 'types/masterData/currencies'
import { arrayFieldDefaultValue } from 'utils/merge/utils'

import { getMinorUnitKeyValue, getMinorUnitNumericValue } from '../utils'

export const convertToForm = ([currency1, currency2]: Currency[]): MergeCurrenciesForm => {
  const isoAlpha3 = currency1.isoAlpha3 || currency2.isoAlpha3
  const numCode = currency1.numCode || currency2.numCode
  const minorUnit = getMinorUnitKeyValue(currency1.minorUnit) || getMinorUnitKeyValue(currency2.minorUnit)
  const withdrawalDate = currency1.withdrawalDate || currency2.withdrawalDate

  return {
    ...defaultFormValues,
    name: currency1.name,
    aliases: arrayFieldDefaultValue(currency1.aliases.length, currency2.aliases.length),
    isoAlpha3,
    numCode,
    minorUnit,
    withdrawalDate,
  }
}

export const convertFormToFormDto = ({ values, data }: ConvertCurrencyFormProps): CurrencyFormDTO => {
  const formValues = convertToTwoEntityValues(data)
  const name = values.isNewName ? values.newName : values.name

  const aliases = convertFormValuesFieldToFormDTO({
    formValues,
    values,
    field: 'aliases',
  })

  return {
    name,
    aliases,
    isoAlpha3: values.isoAlpha3!,
    numCode: values.numCode!,
    minorUnit: getMinorUnitNumericValue(values.minorUnit!),
    withdrawalDate: values.withdrawalDate!,
  }
}
